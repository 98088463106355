.pre-checkout-header {
  margin-top: 55px;
}
#pre-checkout-backward-img {
  width: 22px;
  height: 22px;
  /* padding: 16px, 9px; */
}
.pre-checkout-input-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-top: 31px;
}
.pre-checkout-header {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  color: #0f172a;
  width: 309px;
}
.pre-checkout-text {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #0f172a;
  width: 269px;
}
.display {
  display: flex;
  align-items: center;
  justify-content: center;
}
.final-order {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
  width: 331px;
  height: 167px;
  /* padding-left: 12px; */
  border-radius: 10px;
  background-color: #f1f5f9;
}
.ordered-cont {
  margin: 12px 16px;
}
.order {
  color: #1e293b;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;
  opacity: 0.7;
}
.pre-checkout-order-text {
  color: #0f172a;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.pre-checkout-confirmatory-amount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
}
.confirmatory-amount-input {
  color: #1e293b;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;
  opacity: 0.7;
}
.pre-checkout-confirmatory-amount-figure {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.confirmatory-amount-figure-input {
  color: #00a372;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}
.pre-checkout-order-button {
  margin-top: 88px;
  width: 333px;
  height: 48px;
  background-color: #1a75fd;
  border: 1px solid #1a75fd;
  border-radius: 8px;
  gap: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
