.background {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ff6938; */
  height: 100vh;
}
.background-page {
  width: 100%;
  max-width: 535px;
  /* height: 712px; */
  background-color: #ff6938;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.page-content {
  padding: 1rem;
}
.header-img {
  position: relative;
  top: -25px;
  width: 100%;
}
.background-header {
  margin-top: 35px;
}
#minies-img {
  width: 92px;
  height: 21px;
}
#order-img {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border-radius: 50%;
}
#forward-img-one {
  width: 15px;
  height: 15px;
  margin-left: 35px;
}
/* #forward-img {
  width: 15px;
  height: 15px;
  margin-left: 30px;
} */
#tripplex-img {
  width: 147px;
  height: 31px;
}
.background-headerr {
  max-width: 265px;
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  margin-top: 1rem;
}
.text {
  color: var(--White, #fff);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: 12px;
}

.items-list {
  margin-top: 1.5rem;
}
.items-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 0px;
  border-radius: 10px;
}
.item-one {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-text {
  margin-left: 10px;
  width: 160px;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: var(--grey-900, #0f172a);
}
.item-text-one {
  margin-left: 10px;
  text-align: start;
  width: 149px;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: var(--grey-900, #0f172a);
}
.prices {
  display: flex;
}
.item-price {
  color: #646468;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  margin-left: 10px;
  margin-top: 6px;
}
.item-price span {
  position: relative;
}
.item-price span:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: #ff6938;

  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
.new-item-price {
  color: #ff6938;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  margin-left: 10px;
  margin-top: 6px;
}
.text-line {
  display: flex;
  align-items: center;
}
.powered-by {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  color: var(--White, #fff);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.tripplex {
  display: flex;
  margin-top: 19px;
  margin-bottom: 31px;
  justify-content: center;
}
/* .welcome-page-text {
  text-decoration: none;
} */
@media only screen and (max-width: 600px) {
  .background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff6938;
  }
  .background-page {
    width: 535px;
    /* height: 712px; */
    background-color: #ff6938;
    border-radius: 10px;
  }

  .background-headerr {
    color: var(--White, #fff);
    font-size: 30px;
    font-weight: 800;
    margin-top: 29px;
  }

  .items-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 323px;
    /* height: 95px; */
    background-color: white;
    border-radius: 10px;
    /* padding: 16px 12px; */
    margin-top: 16px;
  }
  .items-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 0px;
    border-radius: 10px;
  }
  /* .item-one {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
  } */
  .item-text-one {
    margin-left: 10px;
    text-align: start;
    width: 152px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--grey-900, #0f172a);
  }
  #forward-img-one {
    width: 15px;
    height: 15px;
    margin-left: 35px;
  }
  /* #forward-img {
    width: 15px;
    height: 15px;
    margin-left: 20px;
  } */
  .layer {
    width: 375px;
    height: 80px;
    margin-top: -80px;
  }
  .slant-line {
    stroke-width: 2px;
    stroke: #ff6938;
    transform: rotate(-11.96deg);
  }
}

@media only screen and (min-width: 375px) {
  .background-page {
    max-width: 450px;
  }
}

@media only screen and (min-width: 500px) {
  .header-img {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .header-img {
    display: none;
  }

  .page-content {
    padding: 1rem 6rem;
  }
}
