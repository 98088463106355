.confirmed-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  border-radius: 108px;
  border: 8px solid #ecfdf3;
  background-color: #d1fadf;
}
#conf-check {
  width: 38px;
  height: 38px;
}
.confirmed-text {
  width: 180px;
  text-align: center;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #0f172a;
}
.order-text {
  color: #0f172a;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 8px;
}
