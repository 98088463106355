.checkout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
}
#checkouts {
  width: 303px;
  height: 295px;
}
.checkout-text {
  margin-top: 25px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  width: 261px;
  color: #0f172a;
}
.final-checkout-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.checkout-texts {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 250px;
  color: #0f172a;
}
#store-icon-background {
  width: 122px;
  height: 25px;
  flex-shrink: 0;
}
.app-store-icon-conts {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  border-radius: 16px;
  background-color: #0f172a;
  margin-top: 92px;
}
.play-store-icon-conts {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  border-radius: 16px;
  background-color: #0f172a;
  margin-top: 92px;
  margin-left: 12px;
}
