* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Manrope", sans-serif;
}

input {
  font-size: 1rem;
}
