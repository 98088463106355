.header {
  display: flex;
  justify-content: space-evenly;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 34px;
  /* margin-left: 20px; */
}
#backward-img {
  width: 22px;
  height: 22px;
  padding: 9px, 16px;
  margin-top: 8px;
}
.page-header {
  align-items: center;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.amount {
  margin-top: 20px;
  color: #0f172a;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
#price-input {
  width: 126px;
  height: 22px;
  border: 0px;
}
.input {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  height: 43px;
}
.line {
  width: 1px;
  height: 22px;
  background-color: #e5e7eb;
  margin-left: 186px;
}
.currency {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #94a3b8;
  margin-left: 20px;
  /* margin-left: 20px; */
}
.input-area {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 20px; */
}
/* .input-cont {
  margin-left: 20px;
} */
.quantity {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.quantity-control {
  display: flex;
  /* width: 334px; */
}
.subtract {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 43px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 6px;
  margin-right: 6px;
}
.add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 43px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 6px;
  margin-left: 6px;
}
.number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 43px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 6px;
  color: #000;
}
.name {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.inputs {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #94a3b8;
  margin-top: 6px;
  height: 43px;
}

.inputs input {
  height: 100%;
  width: 100%;
}
.number-line {
  width: 1px;
  height: 22px;
  background-color: #e5e7eb;
  margin-left: 14px;
}
.number-input {
  margin-left: 12px;
}
.button {
  display: flex;
  justify-content: center;
  margin-top: 54px;
}
#order-button {
  width: 333px;
  height: 48px;
  background-color: #1a75fd;
  border: 1px solid #1a75fd;
  border-radius: 8px;
  gap: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
#downward {
  display: flex;
  align-items: center;
  width: 14px;
  height: 14px;
  margin-left: 8px;
}
.ordered-text {
  color: #0f172a;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin-top: 26px;
  width: 279px;
}
#arithicon {
  width: 20px;
  height: 20px;
}
.amount-input {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #0f172a;
}
input {
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: black;
}
input:focus,
input:active {
  outline: none;
}
input::placeholder {
  color: #94a3b8;
}
.number-input {
  width: 195px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: black;
}
.number-input-error {
  width: 220px;
  border: 1px solid red;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: black;
}
.input-error {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  border: 1px solid red;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: black;
  margin-top: 6px;
}
.amount-inputs {
  display: flex;
  align-items: center;
  width: 335px;
  height: 43px;
  padding: 5px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #94a3b8;
  margin-top: 6px;
}
@media only screen and (max-width: 600px) {
  .header {
    display: flex;
    justify-content: space-between;
  }
}
