.transfer-header {
  margin-top: 47px;
}
.transfer-minies {
  margin-top: 47px;
}
/* .transfers {
  margin-left: 20px;
} */
.bank-account {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin-top: 38px;
}
.transfer-alt {
  margin-top: 38px;
  width: 320px;
  height: 44px;
  font-size: 14px;
}
.account {
  width: 311px;
  height: 180px;
  padding: 10px;
  gap: 8px;
  flex: 1 0 0;
  background-color: #f8fafc;
  margin-top: 24px;
}
.transfer-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #94a3b8;
  margin-top: 8px;
}
.transfer-input {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1a75fd;
  margin-top: 4px;
}
.transfer-number-input {
  display: flex;
  justify-content: space-between;
}
#document-img {
  width: 20px;
  height: 20px;
}
.transfer-button {
  display: flex;
  justify-content: center;
  margin-top: 285px;
}
#transfer-button {
  width: 333px;
  height: 48px;
  background-color: #1a75fd;
  border: 1px solid #1a75fd;
  border-radius: 8px;
  gap: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
