.payment {
  margin-left: 15px;
}
.payment-text {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  color: #0f172a;
  margin-top: 38px;
  width: 279px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 335px;
  /* height: 103px; */
  /* padding: 16px 12px; */
  border: 0.8px solid #cbd5e1;
  border-radius: 16px;
  margin-top: 20px;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  padding: 16px 12px;
  border: 0px;
  border-radius: 16px;
  /* margin-top: 20px; */
  background-color: white;
}
#home-image {
  width: 24px;
  height: 24px;
}
#payment-image {
  width: 24px;
  height: 24px;
}
.transfer-items {
  margin-left: 15px;
  color: #0f172a;
}
.pay {
  width: 159px;
  height: 19px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.alt {
  width: 200px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 8px;
}
#forward {
  width: 15px;
  height: 15px;
  margin-left: 42px;
}
.image-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f5f9;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
/* @media only screen and (max-width: 600px) {
  .payment {
    margin-left: 15px;
  }
} */
