.confirmatory-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  border-radius: 108px;
  border: 8px solid rgba(255, 204, 2, 0.2);
  background-color: #ffcc02;
}
#check {
  width: 38px;
  height: 38px;
}
.check-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 127px;
}
.confirmatory-text-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmatory-text {
  text-align: center;
  width: 281px;
  height: 52px;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #0f172a;
}
.confirmatory-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.confirmatory-amount-input {
  color: #1e293b;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;
  opacity: 0.7;
}
.confirmatory-amount-figure {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmatory-amount-figure-input {
  color: #00a372;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}
.refresh {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 266px;
  height: 22px;
}
.refresh-input {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.refreshing-input {
  color: #000d1f;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  margin-left: 3px;
}
